import React from 'react'

const Dot = () => {
  return (
    <div className="dot">
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 387" width="350" height="387">
	<title>-e-2</title>
	<g id="-e-2">
		<g id="&lt;Group&gt;">
			<g id="&lt;Group&gt;">
				<path id="&lt;Path&gt;" class="s0" d="m179.1 33.7l-299.7 0.4 0.3 214.2 299.7-0.4c59.2-0.1 107.1-48.1 107-107.3-0.1-59.1-48.1-107-107.3-106.9z"/>
			</g>
		</g>
	</g>
	<g id="-e-1">
		<g id="&lt;Group&gt;">
			<g id="&lt;Group&gt;">
				<g id="&lt;Group&gt;">
					<g id="&lt;Group&gt;">
						<path id="&lt;Path&gt;" class="s1" d="m179.8 209.2c0-9.5 7.6-17.1 17.1-17.1 9.5 0 17.1 7.6 17.1 17.1 0 9.4-7.6 17.1-17.1 17.1-9.5 0-17.1-7.7-17.1-17.1z"/>
					</g>
					<g id="&lt;Group&gt;">
						<path id="&lt;Path&gt;" class="s1" d="m131.5 209.2c0-9.5 7.7-17.1 17.2-17.1 9.4 0 17.1 7.6 17.1 17.1 0 9.4-7.7 17.1-17.1 17.1-9.5 0-17.2-7.7-17.2-17.1z"/>
					</g>
					<g id="&lt;Group&gt;">
						<path id="&lt;Path&gt;" class="s1" d="m83.3 209.2c0-9.5 7.7-17.1 17.1-17.1 9.5 0 17.1 7.6 17.1 17.1 0 9.4-7.6 17.1-17.1 17.1-9.4 0-17.1-7.7-17.1-17.1z"/>
					</g>
					<g id="&lt;Group&gt;">
						<path id="&lt;Path&gt;" class="s1" d="m35.1 209.2c0-9.5 7.6-17.1 17.1-17.1 9.4 0 17.1 7.6 17.1 17.1 0 9.4-7.7 17.1-17.1 17.1-9.5 0-17.1-7.7-17.1-17.1z"/>
					</g>
				</g>
			</g>
			<g id="&lt;Group&gt;">
				<path id="&lt;Path&gt;" class="s2" d="m179.8 277.4c0-9.5 7.6-17.1 17.1-17.1 9.5 0 17.1 7.6 17.1 17.1 0 9.5-7.6 17.1-17.1 17.1-9.5 0-17.1-7.6-17.1-17.1z"/>
				<path id="&lt;Path&gt;" class="s2" d="m131.5 277.4c0-9.5 7.7-17.1 17.2-17.1 9.4 0 17.1 7.6 17.1 17.1 0 9.5-7.7 17.1-17.1 17.1-9.5 0-17.2-7.6-17.2-17.1z"/>
				<path id="&lt;Path&gt;" class="s2" d="m83.3 277.4c0-9.5 7.7-17.1 17.1-17.1 9.5 0 17.1 7.6 17.1 17.1 0 9.5-7.6 17.1-17.1 17.1-9.4 0-17.1-7.6-17.1-17.1z"/>
				<path id="&lt;Path&gt;" class="s2" d="m35.1 277.4c0-9.5 7.6-17.1 17.1-17.1 9.4 0 17.1 7.6 17.1 17.1 0 9.5-7.7 17.1-17.1 17.1-9.5 0-17.1-7.6-17.1-17.1z"/>
			</g>
			<g id="&lt;Group&gt;">
				<path id="&lt;Path&gt;" class="s2" d="m179.8 345.6c0-9.4 7.6-17.1 17.1-17.1 9.5 0 17.1 7.7 17.1 17.1 0 9.5-7.6 17.1-17.1 17.1-9.5 0-17.1-7.6-17.1-17.1z"/>
				<path id="&lt;Path&gt;" class="s2" d="m131.5 345.6c0-9.4 7.7-17.1 17.2-17.1 9.4 0 17.1 7.7 17.1 17.1 0 9.5-7.7 17.1-17.1 17.1-9.5 0-17.2-7.6-17.2-17.1z"/>
				<path id="&lt;Path&gt;" class="s2" d="m83.3 345.6c0-9.4 7.7-17.1 17.1-17.1 9.5 0 17.1 7.7 17.1 17.1 0 9.5-7.6 17.1-17.1 17.1-9.4 0-17.1-7.6-17.1-17.1z"/>
				<path id="&lt;Path&gt;" class="s2" d="m35.1 345.6c0-9.4 7.6-17.1 17.1-17.1 9.4 0 17.1 7.7 17.1 17.1 0 9.5-7.7 17.1-17.1 17.1-9.5 0-17.1-7.6-17.1-17.1z"/>
			</g>
		</g>
	</g>
</svg>
    </div>
  )
}

export default Dot