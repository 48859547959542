import React from 'react'

const Tarjeta = () => {
  return (
    <div className="tarjeta">
        <svg
          version="1.2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 1000"
          width="1000"
          height="1000"
        >
          <title>2900294-ai</title>
          <defs>
            <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
              <path d="m623.64 395.09c0 9.52 7.7 16.96 17.19 16.96h9.95c9.49 0 17.19-7.44 17.19-16.96v-33.63c0-9.53-7.7-17.29-17.19-17.29h-9.95c-9.49 0-17.19 7.76-17.19 17.29z" />
            </clipPath>
          </defs>

          <g id="_Artboards_"></g>
          <g id="BACKGROUND">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="s0" d="m228.8 260.6v-158.6" />
                <path
                  id="&lt;Path&gt;"
                  class="s1"
                  d="m274.4 293.2c0 25.2-20.4 45.6-45.6 45.6-25.2 0-45.6-20.4-45.6-45.6 0-25.2 20.4-45.6 45.6-45.6 25.2 0 45.6 20.4 45.6 45.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s2"
                  d="m187.6 273.7h82.4c-7.3-15.4-23-26.1-41.2-26.1-18.2 0-33.9 10.7-41.2 26.1z"
                />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="s0" d="m337.7 220.4v-118.4" />
                <path
                  id="&lt;Path&gt;"
                  class="s1"
                  d="m383.2 252.9c0 25.2-20.4 45.6-45.5 45.6-25.2 0-45.6-20.4-45.6-45.6 0-25.2 20.4-45.6 45.6-45.6 25.1 0 45.5 20.4 45.5 45.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s2"
                  d="m296.5 233.4h82.4c-7.4-15.4-23.1-26.1-41.2-26.1-18.2 0-33.9 10.7-41.2 26.1z"
                />
              </g>
              <g id="&lt;Group&gt;">
                <path id="&lt;Path&gt;" class="s0" d="m283.2 354.2v-252.2" />
                <path
                  id="&lt;Path&gt;"
                  class="s1"
                  d="m328.8 386.8c0 25.1-20.4 45.6-45.6 45.6-25.1 0-45.6-20.5-45.6-45.6 0-25.2 20.5-45.6 45.6-45.6 25.2 0 45.6 20.4 45.6 45.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s2"
                  d="m242 367.2h82.4c-7.3-15.4-23-26-41.2-26-18.1 0-33.8 10.6-41.2 26z"
                />
              </g>
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Path&gt;"
                  class="s0"
                  d="m317 938.3c-12.1 0-22.1-10-22.1-22.2v-39.4c0-12.1 10-22.1 22.1-22.1 12.2 0 22.2 10 22.2 22.1v39.4c0 12.2-10 22.2-22.2 22.2z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s0"
                  d="m328.2 938.3c-12.2 0-22.2-10-22.2-22.2v-39.4c0-12.1 10-22.1 22.2-22.1 12.2 0 22.1 10 22.1 22.1v39.4c0 12.2-9.9 22.2-22.1 22.2z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s1"
                  d="m375.7 1000.4h-99.5v-109.8h99.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s3"
                  d="m375.7 1000.4h-20.5v-109.8h20.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s0"
                  d="m260.2 948.3c-10.3 0-18.6-8.4-18.6-18.6v-33c0-10.2 8.3-18.6 18.6-18.6 10.2 0 18.6 8.4 18.6 18.6v33c0 10.2-8.4 18.6-18.6 18.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s0"
                  d="m269.5 948.3c-10.2 0-18.6-8.4-18.6-18.6v-33c0-10.2 8.4-18.6 18.6-18.6 10.2 0 18.6 8.4 18.6 18.6v33c0 10.2-8.4 18.6-18.6 18.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s1"
                  d="m309.3 1000.4h-83.4v-92.1h83.4z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s3"
                  d="m309.3 1000.4h-17.1v-92.1h17.1z"
                />
              </g>
              <path
                id="&lt;Path&gt;"
                class="s4"
                d="m882.5 872c12.1-15 17-27.9 16.2-34.7-1.6-12-14.5-13-14.2-24.6 0.3-10.1 13.9-15.4 15.5-27.4 1.5-10.8-10.1-10-11.9-22.6-3.3-23.3 20.3-37.7 17.9-63.4-1-10.6-11.8-14.5-14.6-22.7-4.2-11.9-5.1-10.6-6.3-15.7-3.1-12.8 2.6-23.9 6.6-35.1 5-14.1 8.6-32.8 4.2-48-6.2-21.6-25.1 2.5-28.9 14-7 20.6 1.8 54.4-21.2 60.9-12.6 3.5-34.4 45.2-18 74.4 16.5 29.2-32.3 15-22.1 61 10.2 45.9-41.9 25.9-19.3 69.5q48.1 7.2 96.1 14.4z"
              />
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m826.7 712.8q0 0 0.1 0.8 0.1 0.8 0.5 2.3c0.3 1 0.8 2.2 1.4 3.5 0.7 1.3 1.5 2.7 2.5 4.1 2.1 2.9 5 5.8 8.6 8.4 3.6 2.5 7.8 4.7 12.3 6.1 2.2 0.7 4.5 1.2 6.8 1.5 2.3 0.3 4.6 0.5 6.8 0.3 2.2 0 4.3-0.4 6.3-0.8 1-0.2 1.9-0.4 2.8-0.7q1.4-0.4 2.6-0.9c1.7-0.7 3.1-1.3 4.4-2 1.3-0.6 2.3-1.3 3.2-1.9 1.7-1.1 2.7-1.8 2.7-1.8 0 0-1 0.7-2.6 1.9-0.9 0.6-1.9 1.3-3.2 2-1.2 0.7-2.7 1.4-4.4 2.1q-1.2 0.5-2.6 0.9c-0.9 0.3-1.8 0.6-2.8 0.8-2 0.4-4.2 0.8-6.4 0.9-2.2 0.1-4.5 0-6.9-0.3-2.3-0.2-4.6-0.8-6.9-1.5-4.5-1.4-8.8-3.7-12.4-6.3-3.6-2.6-6.5-5.6-8.5-8.5-1.1-1.5-1.9-2.9-2.5-4.2-0.6-1.4-1-2.5-1.3-3.5q-0.3-1.6-0.4-2.4-0.1-0.8-0.1-0.8z"
                  />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m823.7 759.3q0 0 0.2 0.9 0.1 0.8 0.5 2.3c0.3 1 0.7 2.1 1.4 3.4 0.6 1.3 1.4 2.7 2.5 4.1 2 2.9 5 5.9 8.5 8.4 3.6 2.6 7.9 4.8 12.4 6.1 2.2 0.7 4.5 1.3 6.8 1.6 2.3 0.3 4.5 0.4 6.7 0.3 2.3 0 4.4-0.4 6.3-0.8 1-0.2 2-0.5 2.9-0.7q1.3-0.5 2.6-0.9c1.6-0.7 3.1-1.3 4.4-2 1.3-0.7 2.3-1.4 3.2-1.9 1.7-1.2 2.7-1.9 2.7-1.9 0 0-1 0.7-2.7 2-0.8 0.5-1.8 1.3-3.1 2-1.3 0.7-2.8 1.4-4.4 2.1q-1.3 0.4-2.6 0.9c-0.9 0.2-1.9 0.5-2.9 0.8-2 0.4-4.1 0.8-6.3 0.8-2.3 0.2-4.6 0.1-6.9-0.2-2.3-0.3-4.6-0.9-6.9-1.5-4.6-1.5-8.8-3.7-12.4-6.3-3.6-2.7-6.5-5.7-8.6-8.6-1-1.4-1.8-2.9-2.4-4.2-0.6-1.3-1-2.5-1.3-3.5q-0.4-1.5-0.5-2.3-0.1-0.9-0.1-0.9z"
                  />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m884.2 584.3c0 0-0.9 3.7-2.5 10.3-0.8 3.3-1.7 7.3-2.8 11.8-1 4.6-2.2 9.8-3.5 15.5-2.5 11.3-5.3 24.5-8 38.7q-1 5.3-2.1 10.8-1 5.5-2 11.2c-1.4 7.4-2.6 15.1-3.8 22.7-1.3 7.6-2.3 15.2-3.5 22.8-1.1 7.5-1.9 14.9-2.8 22.1-0.8 7.2-1.5 14.2-2.1 20.8-0.5 6.6-0.9 12.8-1.3 18.6-0.3 5.8-0.4 11.1-0.6 15.8-0.2 4.7-0.1 8.8 0 12.2 0.1 6.7 0.2 10.6 0.2 10.6 0 0-0.1-3.9-0.3-10.6-0.1-3.4-0.3-7.5-0.1-12.2 0.2-4.7 0.2-10 0.5-15.8 0.4-5.8 0.8-12.1 1.2-18.7 0.7-6.6 1.3-13.6 2-20.8 1-7.1 1.8-14.6 2.8-22.1 1.2-7.5 2.1-15.2 3.4-22.8 1.3-7.6 2.5-15.3 3.9-22.7q1.1-5.6 2.1-11.2 1-5.4 2.1-10.8c2.7-14.2 5.6-27.4 8.1-38.7 5.1-22.6 9.1-37.5 9.1-37.5z"
                  />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Path&gt;"
                  class="s6"
                  d="m785.8 891.9c-20.3-24.8-12.4-49 0-63.7 12.4-14.6-30.5-50.7 3.3-87.9 33.9-37.2-18-94.7 26-111.6 43.9-16.9 13.5 78.9 27 82.3 13.5 3.4 38.3 26.4 15.8 54.4-22.5 27.9 33.8 14.3 15.8 58.3-18.1 44 42.8 24.8 11.3 66.5-66.6 0-99.2 1.7-99.2 1.7z"
                />
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m817.3 646.6c0 0 4.3 61.3 8.5 122.7 4 61.3 8 122.6 8 122.6 0 0-4.3-61.3-8.5-122.6-4-61.3-8-122.7-8-122.7z"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m799.3 853.9c0 0 3.7 2 9.7 4.3 5.9 2.2 14.2 4.6 22.7 4.4 2.1 0 4.2-0.2 6.2-0.6 2.1-0.4 4.1-0.8 5.9-1.6 1.9-0.7 3.5-1.6 5.1-2.6 1.5-1 2.8-2.1 4-3.2q0.9-0.8 1.5-1.6c0.5-0.6 1-1.1 1.4-1.6 0.8-1 1.5-1.9 2-2.7 1-1.5 1.5-2.4 1.5-2.4 0 0-0.5 0.9-1.4 2.5-0.5 0.8-1.2 1.7-1.9 2.8-0.9 1-1.7 2.2-2.9 3.2-1.2 1.2-2.5 2.3-4 3.3-1.6 1-3.3 2-5.1 2.7-1.9 0.8-3.9 1.3-6 1.7-2 0.4-4.2 0.7-6.3 0.7-8.6 0.1-16.9-2.4-22.8-4.7-6-2.4-9.6-4.6-9.6-4.6z"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m799.3 792.1c0 0 2.2 3.5 6.7 7.7 2.2 2.1 4.9 4.4 8.2 6.3 1.6 1 3.4 1.8 5.3 2.5 1.9 0.7 3.8 1.1 5.8 1.5 2 0.2 4.1 0.3 6 0.1 2-0.2 3.9-0.6 5.8-1.1q2.7-0.9 5-2.1c0.8-0.4 1.5-0.9 2.2-1.3 0.7-0.4 1.4-0.8 2-1.2 1.3-0.9 2.4-1.7 3.4-2.5 1-0.8 1.8-1.4 2.5-2 1.3-1.2 2-1.9 2-1.9 0 0-0.7 0.7-2 1.9-0.6 0.7-1.4 1.4-2.4 2.2-0.9 0.8-2 1.6-3.3 2.5-0.6 0.5-1.3 0.9-2 1.3-0.7 0.4-1.4 0.9-2.2 1.3q-2.4 1.2-5.1 2.2c-1.8 0.5-3.8 1-5.8 1.2-2 0.2-4.1 0.1-6.1-0.1-2.1-0.3-4.1-0.8-6-1.5-1.9-0.8-3.7-1.6-5.3-2.6-3.3-2-6.1-4.3-8.3-6.5-2.2-2.1-3.7-4.1-4.8-5.5-1.1-1.5-1.6-2.4-1.6-2.4z"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m799.3 755.8c0 0 0.9 0.4 2.4 0.8 1.6 0.6 3.9 1.1 6.7 1.7 1.4 0.3 2.9 0.5 4.5 0.7 1.6 0.2 3.3 0.3 5.1 0.4 1.7 0 3.6-0.1 5.4-0.2 1.8-0.3 3.7-0.4 5.5-0.8l2.8-0.6q1.3-0.5 2.7-0.9c1.7-0.5 3.4-1.3 5-2 1.6-0.7 3-1.7 4.4-2.6 0.7-0.4 1.4-0.8 2-1.2q0.8-0.8 1.7-1.5c1-0.9 2.1-1.6 2.9-2.5q1.2-1.2 2-2.1c1.2-1.2 1.8-1.9 1.8-1.9 0 0-0.6 0.7-1.7 2q-0.8 0.9-2 2.2c-0.7 0.9-1.8 1.6-2.9 2.6q-0.8 0.7-1.6 1.4c-0.6 0.5-1.3 0.9-2 1.3-1.4 0.9-2.8 2-4.4 2.6-1.6 0.8-3.3 1.7-5.1 2.2q-1.3 0.4-2.7 0.9l-2.7 0.6c-1.9 0.5-3.8 0.6-5.7 0.8-1.8 0.1-3.7 0.1-5.4 0.1-1.8-0.1-3.5-0.2-5.2-0.5-1.6-0.1-3.1-0.4-4.5-0.7-2.8-0.6-5-1.3-6.6-1.9-1.6-0.5-2.4-0.9-2.4-0.9z"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m805.2 683.1c0 0 0.4 0.3 1.1 0.8 0.7 0.4 1.8 1.1 3.1 1.6 0.7 0.3 1.5 0.4 2.3 0.6 0.8 0.1 1.7 0.3 2.6 0.2 0.9 0.1 1.8-0.2 2.7-0.4l1.3-0.5 0.7-0.3 0.6-0.3 1.2-0.8c0.4-0.2 0.7-0.6 1.1-0.9q0.6-0.4 1.1-0.9 0.4-0.5 0.9-1c0.6-0.7 1.2-1.4 1.6-2.1q0.8-1 1.4-1.9c0.4-0.6 0.7-1.2 1-1.7q0.4-0.8 0.7-1.4c0.5-0.7 0.7-1.2 0.7-1.2 0 0-0.2 0.5-0.6 1.3q-0.2 0.5-0.6 1.4c-0.3 0.5-0.5 1.1-1 1.7q-0.5 1-1.2 2.1c-0.4 0.7-1.1 1.4-1.6 2.1q-0.5 0.5-1 1.1-0.5 0.4-1 0.9c-0.4 0.3-0.8 0.7-1.2 1l-1.2 0.8-0.7 0.3-0.7 0.3-1.4 0.5c-1 0.2-1.9 0.5-2.8 0.4-1 0.1-1.9-0.1-2.7-0.3-0.8-0.2-1.6-0.4-2.3-0.7-1.4-0.6-2.4-1.3-3.1-1.8-0.7-0.6-1-0.9-1-0.9z"
                  />
                </g>
                <path
                  id="&lt;Path&gt;"
                  class="s2"
                  d="m884.7 1000.1h-101.9l-9.5-113.3h120.9z"
                />
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m774.4 900.5c0 0 7.4-0.1 18.5-0.2 11.1 0 25.8-0.1 40.6-0.1 14.7 0 29.5 0.1 40.5 0.1 11.1 0.1 18.5 0.2 18.5 0.2 0 0-7.4 0-18.5 0.1-11 0.1-25.8 0.1-40.5 0.1-14.8 0-29.5 0-40.6-0.1-11.1-0.1-18.5-0.1-18.5-0.1z"
                  />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m849 669.2c0 0 0.1 0.7 0.4 1.9 0.2 0.6 0.5 1.3 0.9 2.1 0.4 0.7 0.9 1.6 1.5 2.5 0.7 0.8 1.4 1.7 2.3 2.5 0.8 0.9 1.9 1.7 2.9 2.5 1.1 0.7 2.2 1.5 3.5 2.1 1.3 0.7 2.6 1.1 3.9 1.6 0.7 0.2 1.4 0.3 2.1 0.5q1 0.3 2.1 0.4 1 0.1 2 0.2 1 0 2 0h1l1-0.2q0.9-0.1 1.8-0.2c1.2-0.3 2.3-0.7 3.4-1q1.4-0.7 2.6-1.2c0.8-0.3 1.4-0.8 1.9-1.1 1.1-0.7 1.7-1.1 1.7-1.1 0 0-0.6 0.4-1.6 1.1-0.5 0.4-1.1 0.9-1.9 1.3q-1.2 0.6-2.6 1.3c-1.1 0.3-2.2 0.7-3.4 1.1q-0.9 0.1-1.8 0.3-1 0.2-2 0.2-1.1 0-2.1 0-1-0.1-2.1-0.2-1.1-0.1-2.1-0.3c-0.7-0.2-1.4-0.3-2.1-0.6-1.4-0.5-2.8-0.9-4-1.6-1.3-0.6-2.4-1.5-3.6-2.2-1-0.9-2.1-1.6-2.9-2.6-0.9-0.8-1.6-1.8-2.3-2.7-0.6-0.8-1-1.7-1.4-2.5-0.3-0.8-0.6-1.6-0.8-2.2-0.2-1.2-0.3-1.9-0.3-1.9z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="OBJECTS">
            <g id="&lt;Group&gt;">
              <path
                id="&lt;Path&gt;"
                class="s6"
                d="m152.9 486.4c-8.7 12.9-15.9 25.9-10.9 33.9 5 8-14.3 17-3.3 34.4 11 17.3 55.6 26.8 73.2 16.1 17.5-10.7 42.1-8.8 31.4-29.8-10.6-21 5-29.1-1.3-43.4-6.3-14.3-1.7-27.3-8.7-39.3-7-12-34.1-17-34.1-17 0 0-38.9 34.1-46.3 45.1z"
              />
              <path
                id="&lt;Path&gt;"
                class="s7"
                d="m339.8 675.5l-70.6-92.8c-9.4 7.6-19.3 15.3-28 24.3l82 83.7z"
              />
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Path&gt;"
                  class="s7"
                  d="m323.2 690.7c0 0 5.5 12.1 14.2 10.6 8.7-1.5 19.9-15.1 2.4-25.8-17.6-10.7-18.6 11.7-16.6 15.2z"
                />
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s8"
                    d="m239.8 547.7c-27.7 0.9-26.5 32.1-26.5 32.1l27.9 27.2c8.9-8.8 19.5-15.9 29.5-23z"
                  />
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m265.4 577.7c-3 1.2-5.6 3-8.2 4.9-2.6 1.9-5 4-7.2 6.2-2.3 2.2-4.4 4.7-6.4 7.1-2 2.5-3.8 5.1-5.7 7.7l2.6-4c0.9-1.3 1.8-2.7 2.8-3.9 1.9-2.6 4-5 6.3-7.3 2.3-2.2 4.7-4.4 7.3-6.2 2.7-1.8 5.5-3.5 8.5-4.5z"
                      />
                    </g>
                  </g>
                  <path
                    id="&lt;Path&gt;"
                    class="s7"
                    d="m354.9 718.1c4.3 4.1 16.2 12.2 17.5 11 1.2-1.2-4.1-7.4-4.1-7.4 0 0 7.4 5.8 9.2 4.1 1.7-1.6-5-8.6-5-8.6 0 0 8.1 6 9.3 3.9 1.1-2-6.6-8.8-6.6-8.8 0 0 7.3 5 8.2 3.5 1-1.6-5.7-9.4-14.1-17.7"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s7"
                    d="m344.1 706.3c0 0 14.1 19.4 20.8 15.5 6.7-3.9 18.2-15.5-4.7-31.8-23-16.4-16.1 16.3-16.1 16.3z"
                  />
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m370.4 708.1c0 0 0.5 0.3 1.2 0.9 0.7 0.5 1.6 1.3 2.6 2 0.9 0.8 1.8 1.5 2.5 2.1 0.7 0.6 1.2 0.9 1.2 0.9 0 0-0.6-0.2-1.4-0.6-0.8-0.5-1.7-1.2-2.7-1.9-0.9-0.8-1.8-1.6-2.4-2.3-0.6-0.7-1-1.1-1-1.1z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m367.8 713.3l8.4 6.3c0 0-0.6-0.2-1.5-0.7-0.9-0.5-2-1.2-3-2-1.1-0.8-2.1-1.7-2.8-2.4-0.6-0.7-1.1-1.2-1.1-1.2z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m365 718.8c0 0 0.5 0.4 1.3 0.9 0.7 0.5 1.7 1.3 2.6 2 1 0.8 2 1.6 2.7 2.1 0.7 0.6 1.2 0.9 1.2 0.9 0 0-0.6-0.1-1.4-0.6-0.8-0.4-1.8-1.2-2.8-1.9-1-0.8-1.9-1.6-2.6-2.3-0.6-0.6-1-1.1-1-1.1z"
                      />
                    </g>
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s4"
                    d="m195.1 977.6l-2.1 17.3h60.6c0 0-4.5-7.4-22.4-10.7-0.7-6.1-0.7-6.1-0.7-6.1z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m253.6 1000.4h-61v-5.5h61z"
                  />
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m193.9 986.7c0 0 0.7 0 1.8 0.3 1 0.3 2.4 0.9 3.5 1.9 0.6 0.5 1.1 1.1 1.4 1.7q0.3 0.5 0.4 0.9 0.2 0.5 0.2 0.9c0.1 0.5 0.1 1 0.1 1.3q0 0.5 0 0.5c-0.1 0-0.1-0.7-0.4-1.7-0.1-0.5-0.4-1.1-0.7-1.6-0.4-0.6-0.8-1.1-1.3-1.6-1.1-0.9-2.3-1.6-3.3-2-1-0.4-1.7-0.6-1.7-0.6z"
                    />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m225.2 976.9q0.2 5.5 0.2 11.1l-0.2-0.3c2.8 0 5.6 0.2 8.4 0.5 2.8 0.5 5.6 1.1 8.2 2.3-2.7-1-5.4-1.5-8.2-1.8-2.8-0.3-5.6-0.4-8.4-0.5-0.2 0-0.3-0.1-0.3-0.2q0-5.6 0.3-11.1z"
                    />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m243.7 987.4c0 0-0.3 0.4-0.6 1-0.3 0.7-0.8 1.5-1.2 2.3-0.4 0.8-0.7 1.7-0.9 2.4-0.3 0.6-0.4 1.1-0.4 1.1q0 0 0-0.3c0-0.2 0-0.5 0-0.9 0.1-0.3 0.2-0.8 0.3-1.2q0.2-0.7 0.5-1.3c0.4-0.9 1-1.7 1.4-2.2 0.5-0.6 0.9-0.9 0.9-0.9z"
                    />
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s4"
                    d="m100.6 977.1l-2.2 17.3h60.6c0 0-4.5-7.5-22.3-10.8-0.7-6-0.7-6-0.7-6z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m159 999.9h-60.9v-5.5h60.9z"
                  />
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m99.4 986.2c0 0 0.7 0 1.8 0.3 1 0.3 2.4 0.9 3.5 1.9 0.5 0.5 1 1.1 1.3 1.7q0.3 0.4 0.4 0.9 0.2 0.5 0.3 0.9c0.1 0.5 0 1 0 1.3q0 0.5 0 0.5c0 0 0-0.8-0.4-1.8-0.1-0.5-0.4-1-0.7-1.6-0.3-0.5-0.8-1-1.3-1.5-1-1-2.3-1.6-3.3-2-0.9-0.4-1.6-0.6-1.6-0.6z"
                    />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m130.6 976.4q0.3 5.5 0.3 11l-0.3-0.2c2.9-0.1 5.7 0.1 8.5 0.5 2.8 0.4 5.6 1.1 8.1 2.2-2.6-0.9-5.4-1.4-8.2-1.7-2.8-0.3-5.6-0.5-8.4-0.5-0.1 0-0.2-0.1-0.2-0.3q0-5.5 0.2-11z"
                    />
                  </g>
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m149.2 986.9c0 0-0.3 0.4-0.6 1-0.4 0.6-0.9 1.4-1.2 2.3-0.4 0.8-0.8 1.7-1 2.4-0.2 0.6-0.4 1.1-0.4 1.1q0 0 0-0.3c0-0.3 0-0.6 0.1-0.9 0-0.4 0.1-0.8 0.3-1.2q0.2-0.7 0.5-1.3c0.4-0.9 0.9-1.7 1.4-2.3 0.5-0.5 0.9-0.8 0.9-0.8z"
                    />
                  </g>
                </g>
                <path
                  id="&lt;Path&gt;"
                  class="s5"
                  d="m238.5 696.5c-32.1 14.3-71.3 9.5-104.1-3.2-3.3 11.9-5.7 24-7 36.3l-27.4 252.8 36.4-0.5 51.1-253.2 23 108.7-16 145 36.5-1.3 27.9-142.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s8"
                  d="m238.5 696.5l-10.5-58.3 13.2-89.1c0 0-15.2-19-42-21.8-2.5-0.3-5-0.4-7.3-0.5-23.3-0.6-42.1 18.7-40.6 41.5l4.8 69.9-10.4 23.5c-4.5 10.3-8.3 20.8-11.3 31.6 32.8 12.7 72 17.5 104.1 3.2z"
                />
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m225.3 543.2l3.6 14c1.2 4.7 2.5 9.4 3.9 14 1.5 4.6 2.9 9.4 3.2 14.2 0.3 4.8-0.1 9.9-2.3 14.2 1.1-2.1 1.5-4.5 1.8-6.9 0.3-2.4 0.3-4.9 0.1-7.3-0.2-2.3-0.6-4.7-1.3-7.1-0.6-2.3-1.4-4.6-2-6.9-1.4-4.7-2.6-9.4-3.7-14.1q-1.8-7-3.4-14.1z"
                    />
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m180.3 530.4c0.7 2.6 1.6 5 2.9 7.3 1.2 2.3 2.7 4.4 4.9 5.9 1 0.7 2.3 1.1 3.5 1.2 1.3 0 2.5-0.3 3.7-0.7 2.3-1.1 4.4-2.7 6.2-4.6q0.1-0.1 0.2-0.1c1.7 0.4 3.4 0.8 5.1 0.9 1.7 0.2 3.6 0.3 5-0.6 0.7-0.5 0.9-1.4 0.8-2.2-0.1-0.8-0.4-1.6-0.8-2.4-0.7-1.6-1.7-3.1-2.4-4.7 0.8 1.6 1.8 3 2.6 4.6 0.4 0.7 0.7 1.6 0.9 2.5 0.1 0.8-0.1 1.9-0.9 2.5-0.8 0.5-1.7 0.7-2.6 0.8-0.8 0-1.7 0-2.6-0.1-1.7-0.1-3.5-0.4-5.2-0.8l0.2-0.1c-1.8 1.9-3.8 3.7-6.3 4.7-1.2 0.5-2.6 0.8-3.9 0.7-1.4-0.1-2.7-0.6-3.8-1.3-2.2-1.5-3.7-3.8-4.9-6.1-1.2-2.3-2-4.8-2.6-7.4z"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m201.7 539.7c2.1 11.5 4.8 23.1 11.1 33.2 2.7 5.2 5 10.6 6.6 16.3 1.5 5.7 2.3 11.7 1.3 17.6-0.5 2.9-1.5 5.7-2.4 8.5l-2.6 8.4-5 16.9-5.1 16.9-2.6 8.4-1.3 4.2c-0.5 1.4-0.9 2.8-1.7 4.1 0.8-1.3 1.1-2.8 1.6-4.1l1.3-4.2 2.5-8.5 4.9-16.9 5-16.9 2.5-8.5c0.9-2.8 1.9-5.6 2.4-8.4 1-5.8 0.2-11.7-1.3-17.4-1.5-5.6-3.8-11-6.4-16.3-1.3-2.6-2.8-5.1-4.1-7.8-1.2-2.7-2.1-5.5-3-8.3-1.6-5.7-2.8-11.4-3.7-17.2z"
                  />
                </g>
                <path
                  id="&lt;Path&gt;"
                  class="s7"
                  d="m209.4 530.4c-1.7 1.7-4.3 3.9-8.2 5.1-10.6 3.4-20-4.3-20.9-5.1q0-26.3 0-52.6h29.1z"
                />
                <path id="&lt;Path&gt;" class="s5" d="m201 511.1h8.4v6.6z" />
                <path
                  id="&lt;Path&gt;"
                  class="s7"
                  d="m224 497.3c-1.4 9.4-10.1 15.7-19.5 14.3l-9.8-1.4c-9.4-1.4-15.9-9.9-14.6-19.3l4.9-33.3c1.4-9.4 10.1-16 19.5-14.6l9.9 1.4c9.4 1.4 15.9 10.2 14.5 19.6z"
                />
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m206.6 495.2c0 0 0.1 0.5 0.5 1.1 0.3 0.7 1.1 1.4 2 1.5 0.5 0.1 1 0 1.4-0.2 0.4-0.1 0.8-0.3 1.2-0.5 0.6-0.4 1.1-0.7 1.1-0.7 0 0-0.3 0.5-1 1-0.6 0.5-1.6 1.1-2.7 1-0.6-0.1-1.2-0.4-1.5-0.7-0.4-0.4-0.7-0.8-0.8-1.2-0.2-0.4-0.2-0.7-0.2-1q0-0.3 0-0.3z"
                  />
                </g>
                <path
                  id="&lt;Path&gt;"
                  class="s7"
                  d="m184.8 483.9c-0.6 4-4.4 6.8-8.5 6.2-4.2-0.6-7.1-4.4-6.5-8.4 0.6-4 4.4-6.8 8.6-6.2 4.1 0.6 7 4.4 6.4 8.4z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s5"
                  d="m204.7 476.9c-0.2 1.4-0.9 2.4-1.6 2.2-0.8-0.1-1.2-1.2-1-2.6 0.2-1.3 0.9-2.3 1.7-2.2 0.7 0.1 1.1 1.3 0.9 2.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s5"
                  d="m225.9 480c-0.2 1.4-1 2.4-1.7 2.2-0.7-0.1-1.1-1.2-0.9-2.6 0.2-1.3 0.9-2.3 1.6-2.2 0.7 0.1 1.2 1.3 1 2.6z"
                />
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m215.5 481.4c0 0 0.1 1 0.3 2.4 0.1 0.7 0.2 1.5 0.4 2.4q0.1 0.7 0.3 1.3c0.2 0.4 0.5 0.8 0.7 0.8 0.1 0.1 0.4 0 0.6 0q0.5-0.1 0.9 0.1c0.5 0.3 0.7 1 0.7 1.5-0.1 1-0.7 1.7-1.2 2.2-0.6 0.5-1.1 0.8-1.5 1q-0.6 0.3-0.6 0.3 0 0 0.6-0.4c0.3-0.2 0.8-0.6 1.3-1.1 0.4-0.5 0.9-1.3 0.9-2.1 0-0.3-0.1-0.7-0.5-0.9-0.3-0.2-0.6-0.1-1.3 0-0.7-0.2-0.9-0.8-1.1-1.2q-0.2-0.7-0.3-1.4c-0.2-0.9-0.2-1.8-0.2-2.5-0.1-1.4 0-2.4 0-2.4z"
                    />
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m199.9 471.7c0 0 0.2-0.3 0.6-0.6 0.4-0.3 1.1-0.6 1.8-0.6q0.6 0 1 0.1c0.4 0.1 0.6 0.3 0.8 0.5 0.5 0.3 0.7 0.6 0.7 0.5 0 0-0.3-0.1-0.8-0.3-0.5-0.1-1.1-0.3-1.7-0.2-1.2 0-2.4 0.6-2.4 0.6z"
                    />
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m221.8 474.9c0 0 0.2-0.3 0.6-0.6 0.4-0.3 1.1-0.6 1.8-0.6q0.6 0 1.1 0.2c0.3 0.1 0.6 0.2 0.8 0.4 0.4 0.3 0.6 0.6 0.6 0.6 0 0-0.3-0.2-0.8-0.4-0.4-0.1-1-0.3-1.6-0.2-1.3 0-2.5 0.6-2.5 0.6z"
                    />
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m176 487.3q-0.4-0.3-0.6-0.7-0.2-0.5-0.3-1c0-0.7 0.2-1.4 0.6-2 0.7-1.1 2.1-1.8 3.5-1.7l-0.3 0.3c0-0.6-0.1-1.2-0.4-1.8-0.2-0.6-0.6-1-1.1-1.4-0.5-0.4-1.1-0.7-1.7-0.8-0.6-0.2-1.3-0.2-1.9 0 1.2-0.6 2.7-0.4 3.9 0.4 1.1 0.8 1.7 2.2 1.8 3.6 0 0.1-0.1 0.3-0.3 0.3-0.6-0.1-1.2 0-1.8 0.2-0.5 0.3-1 0.7-1.4 1.2-0.3 0.5-0.6 1.1-0.6 1.7q0 0.4 0.1 0.9 0.2 0.4 0.5 0.8z"
                    />
                  </g>
                </g>
                <path
                  id="&lt;Path&gt;"
                  class="s9"
                  d="m203 488.7c-0.2 1.4-2.1 2.3-4.2 2-2.1-0.3-3.7-1.7-3.5-3.1 0.2-1.4 2.1-2.2 4.2-1.9 2.1 0.3 3.7 1.7 3.5 3z"
                />
                <g id="&lt;Clip Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s9"
                    d="m224 497.3c-1.4 9.4-10.1 15.7-19.5 14.3l-9.8-1.4c-9.4-1.4-15.9-9.9-14.6-19.3l4.9-33.3c1.4-9.4 10.1-16 19.5-14.6l9.9 1.4c9.4 1.4 15.9 10.2 14.5 19.6z"
                  />
                </g>
                <path id="&lt;Path&gt;" class="s5" d="" />
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m136 687.9c0 0 1.5 0.6 4.2 1.5 2.7 0.9 6.6 2 11.3 3.3 2.4 0.6 4.9 1.3 7.7 1.9q2 0.5 4.2 0.9 2.1 0.4 4.4 0.9c3 0.6 6.1 1 9.2 1.4 3.2 0.5 6.5 0.7 9.7 1.1 3.2 0.1 6.5 0.4 9.7 0.4q2.4 0 4.7 0c1.6 0 3.1 0 4.6-0.1q2.3-0.2 4.5-0.4 1.1 0 2.2-0.1 1-0.2 2.1-0.4c2.7-0.4 5.4-0.7 7.7-1.3q3.6-0.8 6.5-1.6c1.9-0.5 3.5-1.2 4.9-1.6 2.6-1 4.2-1.5 4.2-1.5 0 0-1.6 0.6-4.2 1.6-1.4 0.5-2.9 1.2-4.8 1.7q-2.9 0.8-6.5 1.7c-2.4 0.7-5 0.9-7.8 1.4q-1 0.2-2.1 0.3-1 0.1-2.1 0.2-2.2 0.2-4.5 0.4c-1.6 0.2-3.1 0.2-4.7 0.2q-2.3 0-4.7 0c-3.3 0-6.5-0.2-9.8-0.4-3.2-0.3-6.5-0.6-9.6-1.1-3.2-0.5-6.3-0.9-9.3-1.5q-2.3-0.5-4.4-0.9-2.2-0.5-4.2-0.9c-2.8-0.6-5.3-1.4-7.7-2-9.4-2.6-15.4-5.1-15.4-5.1z"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m187.5 728.7c0 0 1.4-0.4 3.6-1.1 2.1-0.6 5-1.4 7.9-2.2 2.9-0.7 5.8-1.4 8-1.9 2.2-0.5 3.6-0.8 3.6-0.8 0 0-1.4 0.4-3.6 1.1-2.1 0.6-5 1.4-7.9 2.2-2.9 0.7-5.8 1.4-8 1.9-2.2 0.5-3.6 0.8-3.6 0.8z"
                  />
                </g>
                <path
                  id="&lt;Path&gt;"
                  class="s7"
                  d="m343.1 679.6c0 0 3 10.7-1.5 13.7-4.5 3-3-6.8-3-6.8 0 0-4.2-0.2-4.5-4.4"
                />
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s4"
                    d="m509.1 695.3l-237.4 172.6c-5.4 3.9-12.9 2.7-16.8-2.7l-107.3-147.5c-3.9-5.3-2.7-12.9 2.7-16.8l237.4-172.6c5.4-3.9 12.9-2.7 16.8 2.7l107.3 147.5c3.9 5.4 2.7 12.9-2.7 16.8z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m233.3 753l1.8-1.3c2.4-1.7 5.7-1.2 7.4 1.2l16.5-12-8.1-11.1-22.6 16.4z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m228.3 746.2l-22.5 16.5 4.9 6.8 22.6-16.5z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m250.9 729.8v-0.1c-2.4-3.3-7-4-10.3-1.6l-16.7 12.1 4.4 6z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m239.5 761.6l-22.6 16.4 4.9 6.8 22.6-16.4z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m242.5 752.9c1.7 2.3 1.2 5.6-1.2 7.4l-1.8 1.3 4.9 6.8 22.6-16.5-8-11z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m248.8 774.4l16.7-12.1c3.3-2.4 4-7 1.6-10.2l-0.1-0.2-22.6 16.5z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m223.9 740.2l-16.6 12.1c-3.3 2.4-4 7-1.6 10.3l0.1 0.1 22.5-16.5z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m221.8 784.8l0.1 0.1c2.4 3.3 7 4 10.3 1.6l16.6-12.1-4.4-6z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s6"
                    d="m235.1 751.7l-24.4 17.8 6.2 8.5 24.4-17.8c2.4-1.7 2.9-5 1.2-7.3-1.7-2.4-5-2.9-7.4-1.2z"
                  />
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s10"
                        d="m250.9 729.8l-22.6 16.4 5 6.8 1.8-1.3c2.4-1.7 5.7-1.2 7.4 1.2l16.5-12"
                      />
                      <path
                        id="&lt;Path&gt;"
                        class="s10"
                        d="m210.7 769.5l22.6-16.5-5-6.8-22.5 16.5"
                      />
                      <path
                        id="&lt;Path&gt;"
                        class="s10"
                        d="m223.9 740.2l4.4 6 22.6-16.4v-0.1"
                      />
                      <path
                        id="&lt;Path&gt;"
                        class="s10"
                        d="m221.8 784.8l22.6-16.4-4.9-6.8-22.6 16.4"
                      />
                      <path
                        id="&lt;Path&gt;"
                        class="s10"
                        d="m259 740.9l-16.5 12c1.7 2.3 1.2 5.6-1.2 7.4l-1.8 1.3 4.9 6.8 22.6-16.5"
                      />
                      <path
                        id="&lt;Path&gt;"
                        class="s10"
                        d="m267.1 752.1l-0.1-0.2-22.6 16.5 4.4 6"
                      />
                      <path
                        id="&lt;Path&gt;"
                        class="s10"
                        d="m205.7 762.6l0.1 0.1 22.5-16.5-4.4-6"
                      />
                      <path
                        id="&lt;Path&gt;"
                        class="s10"
                        d="m248.8 774.4l-4.4-6-22.6 16.4 0.1 0.1"
                      />
                    </g>
                    <path
                      id="&lt;Path&gt;"
                      class="s10"
                      d="m216.9 778l24.4-17.8c2.4-1.7 2.9-5 1.2-7.3-1.7-2.4-5-2.9-7.4-1.2l-24.4 17.8"
                    />
                  </g>
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m298.4 780.1l-52.3 38.1-12.5-17.2 52.4-38z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m355.5 738.6l-52.3 38.1-12.5-17.2 52.4-38z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m412.6 697.1l-52.3 38.1-12.5-17.2 52.3-38z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m469.7 655.6l-52.3 38.1-12.5-17.2 52.3-38z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m207.9 739.7l-10.5 7.6-19.5-26.9 10.5-7.6z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m222.1 729.4l-8.6 6.2-19.5-26.9 8.5-6.2z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m237.7 718l-10.1 7.3-19.5-26.9 10-7.3z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m253.5 706.5l-10.2 7.4-19.6-26.8 10.3-7.5z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m269.6 694.8l-10.5 7.6-19.5-26.9 10.5-7.6z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m283.8 684.5l-8.6 6.2-19.5-26.8 8.6-6.3z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m299.4 673.1l-10 7.3-19.5-26.9 10-7.3z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m315.8 661.2l-10.8 7.9-19.5-26.9 10.8-7.9z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s11"
                    d="m334.9 647.3l-13.5 9.8-19.5-26.8 13.5-9.9z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m295.3 818.8l-31.9 23.2-4.5-6.2 31.9-23.2z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m340.5 786l-31.9 23.1-4.5-6.1 31.9-23.2z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m487 679.5l-80.4 58.4-4.5-6.2 80.4-58.4z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s12"
                    d="m407.2 574.2c11.1 15.2 7.7 36.4-7.5 47.5-15.1 11-36.4 7.6-47.4-7.5-11-15.2-7.7-36.5 7.5-47.5 15.2-11 36.4-7.7 47.4 7.5z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s12"
                    d="m401.3 578.5c11 15.2 10.3 34.5-1.6 43.2-11.9 8.6-30.4 3.3-41.5-11.9-11-15.1-10.3-34.4 1.6-43.1 11.9-8.6 30.5-3.3 41.5 11.8z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s12"
                    d="m387.5 588.6c11 15.1 16.5 30 12.2 33.1-4.2 3.1-16.6-6.7-27.7-21.9-11-15.2-16.5-30-12.2-33.1 4.3-3.1 16.7 6.7 27.7 21.9z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s12"
                    d="m352.3 614.2l54.8-39.9"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s12"
                    d="m346 598.2l47.9-34.8"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s12"
                    d="m365.6 625.1l47.8-34.8"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s13"
                    d="m365.8 508.8l10.1 13.2 303.3-231.6-10.1-13.3c-8.1-10.6-23.3-12.6-33.9-4.5l-264.9 202.3c-10.6 8.1-12.6 23.3-4.5 33.9z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s8"
                    d="m369.4 513.5l10.1 13.2 303.3-231.6-10.1-13.2c-8.1-10.7-23.3-12.7-33.9-4.6l-264.9 202.3c-10.6 8.1-12.6 23.3-4.5 33.9z"
                  />
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s8"
                      d="m715 556l-18.1-23.7 129.3-98.7 18.1 23.7c25.8 33.8 10.5 73.8-25.1 101.1-35.7 27.2-78.4 31.4-104.2-2.4z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s8"
                      d="m383 575.2l105.5 138c13.1 17.3 37.8 20.6 55 7.4l285.8-218.2c17.2-13.2 20.5-37.9 7.3-55.1l-105.4-138.1c-13.2-17.2-37.8-20.5-55.1-7.3l-285.7 218.2c-17.2 13.2-20.5 37.8-7.4 55.1z"
                    />
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m736.5 573.2c0 0 8.6-6.6 21.4-16.6 12.9-9.8 30.1-23 47.3-36.2 4.3-3.3 8.6-6.5 12.8-9.8q3.2-2.4 6.3-4.8c2.1-1.5 4.2-3.1 6.1-4.7 3.8-3.3 6.9-7.2 9.1-11.3 2.3-4 3.7-8.2 4.5-12.2 0.7-3.9 0.8-7.7 0.5-11-0.3-3.2-1.1-6-1.8-8.3-0.8-2.2-1.5-3.9-2.2-5-0.6-1.1-0.9-1.7-0.9-1.7 0 0 0.3 0.6 0.9 1.7 0.7 1.1 1.4 2.8 2.3 5 0.7 2.2 1.6 5 1.9 8.3 0.2 1.7 0.2 3.4 0.2 5.3-0.1 1.8-0.2 3.8-0.6 5.8-0.8 4-2.2 8.2-4.4 12.3-2.3 4.1-5.4 8-9.2 11.4-1.9 1.7-4 3.2-6.1 4.8q-3.1 2.4-6.3 4.8c-4.2 3.3-8.5 6.6-12.8 9.9-17.2 13.1-34.5 26.2-47.4 36-12.9 9.8-21.6 16.3-21.6 16.3z"
                        />
                      </g>
                    </g>
                    <path
                      id="&lt;Path&gt;"
                      class="s13"
                      d="m364.6 551l105.4 138.1c13.2 17.2 37.9 20.5 55.1 7.4l285.7-218.3c17.3-13.1 20.6-37.8 7.4-55l-105.4-138.1c-13.2-17.2-37.8-20.5-55.1-7.4l-285.7 218.3c-17.3 13.2-20.6 37.8-7.4 55z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s14"
                      d="m560.5 390.2l116.3 152.2 108.2-82.6-116.3-152.2z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s14"
                      d="m634.6 543.7l10.8 14.1c2.3 3 6.6 3.6 9.6 1.3l0.3-0.2c3-2.3 3.5-6.6 1.2-9.6l-10.7-14.1c-2.3-3-6.6-3.6-9.6-1.3l-0.3 0.2c-3 2.3-3.6 6.6-1.3 9.6z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s14"
                      d="m612.6 514.8l10.8 14.2c2.3 3 6.5 3.5 9.5 1.2l0.3-0.2c3-2.3 3.6-6.5 1.3-9.5l-10.8-14.2c-2.2-3-6.5-3.5-9.5-1.2l-0.3 0.2c-3 2.3-3.6 6.6-1.3 9.5z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s14"
                      d="m559.4 445.2l10.8 14.2c2.3 3 6.6 3.5 9.6 1.2l0.3-0.2c3-2.3 3.5-6.5 1.3-9.5l-10.8-14.2c-2.3-3-6.6-3.5-9.6-1.2l-0.3 0.2c-3 2.3-3.5 6.6-1.3 9.5z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s14"
                      d="m537.4 416.4l10.8 14.1c2.3 3 6.6 3.6 9.6 1.3l0.3-0.2c2.9-2.3 3.5-6.6 1.2-9.6l-10.7-14.1c-2.3-3-6.6-3.6-9.6-1.3l-0.3 0.3c-3 2.2-3.6 6.5-1.3 9.5z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s4"
                      d="m578.2 478.3l16.7 21.9c2.4 3.2 6.9 3.8 10.1 1.4l7.9-6.1c3.2-2.4 3.8-6.9 1.4-10.1l-16.7-21.8c-2.4-3.2-6.9-3.8-10.1-1.4l-7.9 6.1c-3.2 2.4-3.8 6.9-1.4 10z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s2"
                      d="m580.3 557.7l24.8 32.4c1.9 2.5 5.4 3 7.8 1.1l15.8-12.1c2.5-1.8 2.9-5.3 1.1-7.8l-24.8-32.4c-1.9-2.5-5.4-2.9-7.8-1.1l-15.8 12.1c-2.5 1.8-2.9 5.3-1.1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s2"
                      d="m495.6 446.7l24.8 32.5c1.9 2.5 5.4 2.9 7.8 1.1l15.8-12.1c2.4-1.8 2.9-5.3 1-7.8l-24.8-32.5c-1.8-2.4-5.3-2.9-7.8-1l-15.7 12.1c-2.5 1.8-2.9 5.3-1.1 7.7z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s2"
                      d="m538 502.2l24.8 32.5c1.8 2.4 5.3 2.9 7.8 1l15.8-12c2.4-1.9 2.9-5.4 1-7.8l-24.8-32.5c-1.9-2.4-5.4-2.9-7.8-1l-15.8 12c-2.4 1.9-2.9 5.4-1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s2"
                      d="m547.5 582.7l24.8 32.5c1.9 2.5 5.4 2.9 7.8 1.1l15.8-12.1c2.4-1.9 2.9-5.3 1-7.8l-24.8-32.5c-1.8-2.4-5.3-2.9-7.8-1l-15.7 12c-2.5 1.9-3 5.4-1.1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s2"
                      d="m462.8 471.8l24.8 32.5c1.9 2.4 5.4 2.9 7.8 1l15.8-12c2.4-1.9 2.9-5.4 1-7.8l-24.8-32.5c-1.8-2.4-5.3-2.9-7.8-1l-15.8 12c-2.4 1.9-2.9 5.4-1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s2"
                      d="m505.2 527.3l24.8 32.5c1.8 2.4 5.3 2.9 7.8 1l15.7-12.1c2.5-1.8 2.9-5.3 1.1-7.7l-24.8-32.5c-1.9-2.5-5.4-2.9-7.8-1.1l-15.8 12.1c-2.4 1.8-2.9 5.3-1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s2"
                      d="m514.7 607.8l24.8 32.5c1.9 2.4 5.3 2.9 7.8 1l15.8-12c2.4-1.9 2.9-5.4 1-7.8l-24.8-32.5c-1.9-2.4-5.3-2.9-7.8-1l-15.8 12c-2.4 1.9-2.9 5.4-1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s2"
                      d="m430 496.9l24.8 32.5c1.8 2.4 5.3 2.9 7.8 1l15.8-12c2.4-1.9 2.9-5.4 1-7.8l-24.8-32.5c-1.9-2.5-5.4-2.9-7.8-1.1l-15.8 12.1c-2.4 1.9-2.9 5.3-1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s2"
                      d="m472.3 552.3l24.8 32.5c1.9 2.5 5.4 2.9 7.8 1.1l15.8-12.1c2.5-1.8 2.9-5.3 1.1-7.8l-24.9-32.5c-1.8-2.4-5.3-2.9-7.7-1l-15.8 12.1c-2.5 1.8-2.9 5.3-1.1 7.7z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s14"
                      d="m481.9 632.9l24.8 32.5c1.8 2.4 5.3 2.9 7.8 1l15.7-12.1c2.5-1.8 3-5.3 1.1-7.7l-24.8-32.5c-1.9-2.5-5.4-2.9-7.8-1.1l-15.8 12.1c-2.4 1.8-2.9 5.3-1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s4"
                      d="m397.1 522l24.9 32.4c1.8 2.5 5.3 2.9 7.7 1.1l15.8-12.1c2.5-1.8 2.9-5.3 1.1-7.8l-24.8-32.4c-1.9-2.5-5.4-3-7.8-1.1l-15.8 12.1c-2.4 1.8-2.9 5.3-1.1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s15"
                      d="m439.5 577.4l24.8 32.5c1.9 2.4 5.4 2.9 7.8 1l15.8-12c2.4-1.9 2.9-5.4 1-7.8l-24.8-32.5c-1.8-2.4-5.3-2.9-7.8-1l-15.8 12c-2.4 1.9-2.9 5.4-1 7.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s3"
                      d="m938.2 387.3c11.8 15.5 8.1 38.4-8.3 50.9l-34.7 26.5-43-56.3 16.6-12.6 7.3-5.6 10.8-8.3c16.4-12.5 39.4-10 51.3 5.4z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m687.6 289.8l119.5 156.5c1 1.3 2.8 1.5 4 0.6l0.2-0.1c1.2-1 1.5-2.8 0.5-4.1l-119.6-156.5c-0.9-1.3-2.7-1.5-4-0.6l-0.1 0.1c-1.3 1-1.5 2.8-0.5 4.1z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s1"
                      d="m821.9 235.1l116.3 152.2c-11.9-15.4-34.9-17.9-51.3-5.4l-79.1 60.4-116.3-152.2 79.2-60.4c16.3-12.5 39.4-10.1 51.2 5.4z"
                    />
                  </g>
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s8"
                    d="m181 548.7c-27.7 0.9-26.6 32.1-26.6 32.1l28 27.2c8.9-8.8 19.4-15.9 29.5-23z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s7"
                    d="m311.5 701.8l-99.6-116.8c-10.1 7.1-20.6 14.2-29.5 23l111.7 108.3z"
                  />
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m190.5 556.6c3.8 4.6 7.4 9.3 10.9 14 3.6 4.8 7.1 9.5 10.5 14.4-3.8-4.6-7.3-9.3-10.9-14-3.5-4.8-7.1-9.5-10.5-14.4z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m157.9 583.5c4.2 3.9 8.3 8 12.4 12 4.1 4.1 8.2 8.2 12.1 12.5-4.3-3.9-8.4-8-12.5-12.1-4-4.1-8.1-8.2-12-12.4z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m206.6 578.7c-3 1.2-5.7 3-8.3 4.9-2.5 1.9-4.9 4-7.2 6.2-2.2 2.2-4.3 4.7-6.3 7.2-2 2.4-3.9 5-5.7 7.7l2.5-4.1c1-1.3 1.9-2.6 2.8-3.9 2-2.5 4.1-5 6.3-7.3 2.3-2.2 4.8-4.3 7.4-6.2 2.6-1.8 5.4-3.5 8.5-4.5z"
                      />
                    </g>
                  </g>
                  <path
                    id="&lt;Path&gt;"
                    class="s7"
                    d="m296.1 719.1c4.3 4.1 16.2 12.2 17.4 11 1.3-1.2-4-7.4-4-7.4 0 0 7.3 5.8 9.1 4.2 1.8-1.7-4.9-8.7-4.9-8.7 0 0 8.1 6 9.2 3.9 1.2-2-6.5-8.8-6.5-8.8 0 0 7.2 5 8.2 3.5 0.9-1.6-5.7-9.4-14.2-17.7"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s7"
                    d="m285.2 707.3c0 0 14.2 19.4 20.9 15.5 6.7-3.9 18.2-15.5-4.8-31.8-23-16.4-16.1 16.3-16.1 16.3z"
                  />
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m311.5 709.1c0 0 0.5 0.3 1.3 0.9 0.7 0.5 1.6 1.3 2.5 2 0.9 0.8 1.9 1.5 2.6 2.1 0.6 0.6 1.1 0.9 1.1 0.9 0 0-0.5-0.2-1.3-0.6-0.8-0.5-1.8-1.2-2.7-1.9-1-0.8-1.8-1.6-2.5-2.3-0.6-0.6-1-1.1-1-1.1z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m309 714.3l8.4 6.3c0 0-0.7-0.2-1.5-0.7-0.9-0.5-2-1.2-3.1-2-1-0.8-2-1.7-2.7-2.4-0.7-0.7-1.1-1.2-1.1-1.2z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m306.1 719.8c0 0 0.5 0.4 1.3 0.9 0.8 0.5 1.7 1.3 2.7 2.1 1 0.7 1.9 1.5 2.6 2 0.8 0.6 1.3 0.9 1.3 0.9 0 0-0.6-0.1-1.4-0.6-0.9-0.4-1.9-1.2-2.9-1.9-0.9-0.8-1.8-1.6-2.5-2.2-0.7-0.7-1.1-1.2-1.1-1.2z"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="&lt;Path&gt;"
                  class="s7"
                  d="m310.4 700.3c0 0 7 5.6 8.8 3.9 1.7-1.7-5.4-14-17.6-10"
                />
                <g id="&lt;Group&gt;">
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s7"
                      d="m765.9 583.3c0 0-28.6 97-58.8 66.6-24.3-24.4-20.4-75-20.4-75l37-6.4 6 39.1 16.7-35.6z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s1"
                      d="m725.5 562.6c-1.5-14.1-10.1-24.4-23.4-29.4-6.5-2.4-14.5-1.5-18.3 3.9-10.1 14.3 4 49 9.9 66.8 14.1-0.9 23.1 1.6 35.7-5z"
                    />
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m691.7 597.9c0 0 2.3-0.1 5.9-0.4 1.8-0.1 3.8-0.2 6-0.4 2.2-0.2 4.6-0.4 6.9-0.7 1.2-0.2 2.4-0.3 3.5-0.5 1.2-0.2 2.3-0.4 3.4-0.6 1.1-0.2 2.1-0.4 3.1-0.7q1.5-0.3 2.7-0.8 1.3-0.3 2.3-0.8c0.7-0.3 1.3-0.5 1.7-0.7 0.9-0.6 1.4-0.9 1.4-0.9 0 0-0.5 0.4-1.4 0.9-0.4 0.4-1 0.6-1.6 0.9q-1 0.5-2.3 0.9-1.2 0.5-2.7 0.9-1.5 0.4-3.1 0.8c-1.1 0.2-2.3 0.4-3.4 0.6-1.2 0.2-2.3 0.3-3.5 0.5-4.7 0.6-9.5 0.9-13 1-3.6 0-5.9 0-5.9 0z"
                        />
                      </g>
                    </g>
                    <path
                      id="&lt;Path&gt;"
                      class="s7"
                      d="m755.4 584.4c0.6 0.5 10.1 6.8 14 2.9 0.3-0.2 0.4-1 0.5-1.1 1.7 0.5 3.3 0.2 4.2-0.8 0.5-0.5 0.7-1.1 0.8-1.5 2 0.9 3.7 0.7 4.5 0.8 2.3 0 4.7-0.7 5.3-3.4 0.7-3-4.9-2-4.9-2 0 0 13.8-0.4 10.8-6.7-1.3-2.8-4.9 3.6-15-3.2 0 0 7.9 4.5 9 0.8 0.7-2.4-4.3-6.9-10.6-8.2-1.9-0.4-19-8.8-27.6 10.1"
                    />
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m773.6 576.8q1.3 0.8 2.6 1.3c0.9 0.3 1.8 0.6 2.7 0.8q1.4 0.2 2.9 0.1c0.9 0 1.8-0.2 2.8-0.4-0.9 0.4-1.8 0.8-2.8 0.9-1 0.1-2 0.1-3-0.1-1.9-0.4-3.8-1.2-5.2-2.6z"
                        />
                      </g>
                    </g>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m772.4 567.9l4.5 1.8c1.5 0.7 3.1 1.2 4.6 1.9q-1.2-0.2-2.4-0.6c-0.8-0.2-1.6-0.5-2.4-0.8-1.5-0.6-3-1.3-4.3-2.3z"
                        />
                      </g>
                    </g>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m770.1 580.8c0.5 0.5 1.1 0.9 1.6 1.2 0.6 0.3 1.2 0.7 1.9 0.9 1.2 0.6 2.4 1.1 3.7 1.7q-1-0.2-2-0.4-1-0.3-2-0.8c-1.2-0.6-2.5-1.3-3.2-2.6z"
                        />
                      </g>
                    </g>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m766 584.8c0.9 0.1 1.8 0.4 2.7 0.6 0.9 0.3 1.8 0.6 2.6 1-0.9 0-1.9-0.2-2.8-0.4-0.9-0.3-1.7-0.6-2.5-1.2z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m734 977.4l2.1 17.4h-64.7c0 0 4.5-7.5 22.3-10.8 0.7-6 0.7-6 0.7-6z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s16"
                      d="m671.4 1000.3h65.1v-5.5h-65.1z"
                    />
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s14"
                          d="m735.2 986.6c0 0-1.6 0.1-4 0.4-2.4 0.3-5.5 0.7-8.7 1.4-3.1 0.6-6.1 1.5-8.2 2.7q-0.8 0.4-1.3 0.9-0.5 0.5-0.7 1c-0.3 0.6-0.3 1.1-0.3 1.1 0 0-0.1-0.5 0.2-1.1q0.2-0.5 0.7-1.1 0.5-0.6 1.2-1.1c2.1-1.4 5.2-2.3 8.3-3 3.2-0.6 6.4-0.9 8.8-1.1 2.4-0.1 4-0.1 4-0.1z"
                        />
                      </g>
                    </g>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s14"
                          d="m677.5 989.5c0 0-0.2 0.3-0.6 0.8-0.3 0.5-0.8 1-1.2 1.7-0.8 1.2-1.3 2.6-1.3 2.6 0 0-0.1-0.4 0.1-1 0.1-0.6 0.4-1.3 0.8-1.9 0.4-0.7 0.9-1.3 1.4-1.6 0.4-0.4 0.8-0.6 0.8-0.6z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m625.9 977l2.2 17.3h-64.8c0 0 4.5-7.5 22.3-10.8 0.7-6 0.7-6 0.7-6z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s16"
                      d="m563.3 999.8h65.1v-5.5h-65.1z"
                    />
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s14"
                          d="m627.1 986.1c0 0-1.6 0.1-4 0.5-2.3 0.3-5.5 0.7-8.6 1.3-3.1 0.6-6.2 1.5-8.2 2.7q-0.8 0.5-1.3 1-0.5 0.5-0.8 0.9c-0.3 0.7-0.2 1.1-0.2 1.1 0 0-0.1-0.4 0.1-1.1q0.2-0.5 0.7-1.1 0.5-0.6 1.3-1.1c2-1.3 5.2-2.3 8.3-2.9 3.1-0.6 6.3-1 8.7-1.2 2.4-0.1 4-0.1 4-0.1z"
                        />
                      </g>
                    </g>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s14"
                          d="m569.4 989.2c0 0-0.2 0.3-0.5 0.8-0.4 0.4-0.8 1-1.2 1.6-0.8 1.2-1.4 2.6-1.4 2.6 0 0 0-0.4 0.1-0.9 0.1-0.6 0.4-1.3 0.8-2 0.5-0.6 1-1.2 1.4-1.6 0.5-0.3 0.8-0.5 0.8-0.5z"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    id="&lt;Path&gt;"
                    class="s8"
                    d="m703 669.2l37.6 313.1h-59.5l-21.5-240.9-28.2 90.7 0.5 148-53.4-1.1 1.3-123.1c0.2-21.3 2.8-42.6 7.8-63.4l29.3-123.3z"
                  />
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m739.2 958.7c0 0-3.6 0.1-9.1 0.2-5.4 0-12.7 0.1-20 0.1-7.2 0-14.5-0.1-20-0.1-5.4-0.1-9-0.2-9-0.2 0 0 3.6 0 9-0.1 5.5-0.1 12.8-0.1 20-0.1 7.3 0 14.6 0 20 0.1 5.5 0.1 9.1 0.1 9.1 0.1z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m631.2 957.6c0 0-3.3 0-8.2 0.1-5 0.1-11.6 0.1-18.1 0.2-6.6-0.1-13.2-0.1-18.1-0.2-5-0.1-8.3-0.1-8.3-0.1 0 0 3.3-0.1 8.3-0.2 4.9 0 11.5-0.1 18.1-0.1 6.5 0 13.1 0.1 18.1 0.1 4.9 0.1 8.2 0.2 8.2 0.2z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m699.5 738.9c0 0-4.1 0.3-10.1 0.8-6.1 0.4-14.1 1-22.2 1.5-8.1 0.5-16.1 1-22.2 1.3-6.1 0.3-10.1 0.5-10.1 0.5 0 0 4-0.3 10.1-0.8 6-0.4 14.1-1 22.2-1.5 8-0.5 16.1-1 22.2-1.3 6-0.3 10.1-0.5 10.1-0.5z"
                      />
                    </g>
                  </g>
                  <path
                    id="&lt;Path&gt;"
                    class="s7"
                    d="m640.9 534.8h29.1v-52.6h-29.1z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m646.4 514.1h-5.5v4.4z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s7"
                    d="m625.6 497.1c0 9.5 7.7 17 17.2 17h10c9.5 0 17.2-7.5 17.2-17v-33.6c0-9.6-7.7-17.3-17.2-17.3h-10c-9.5 0-17.2 7.7-17.2 17.3z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m651.8 483.4c0 0-6.7 9.6-0.3 15.4 6.4 5.8 7.2 15.7 16.4 15.3 12.5-0.7 14.3-34.1 15.2-40.4 6.5-43.1-34.4-50.8-47.2-40.1-11.2-3.5-24.8-2.3-22.9 14.3 1.1 10.9 20.1 16.9 26 17.9 5.9 1-4.2 25.7 12.8 17.6z"
                  />
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s7"
                      d="m644.1 487.4c0 4.1 2.4 7.3 5.3 7.3 3 0 5.4-3.2 5.4-7.3 0-4.1-2.4-7.4-5.4-7.4-2.9 0-5.3 3.3-5.3 7.4z"
                    />
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m648.3 492.1c0.7-1 0.7-2.2 0.4-3.2-0.2-0.5-0.5-1-0.8-1.4q-0.3-0.3-0.6-0.5c-0.2-0.1-0.5-0.2-0.7-0.2-0.2 0-0.3-0.2-0.3-0.3 0.2-1.1 0.5-2.2 1.4-3 0.4-0.4 1-0.7 1.6-0.7 0.5-0.1 1.1 0.2 1.4 0.6-0.8-0.7-2.1-0.4-2.7 0.4-0.7 0.7-1 1.7-1.1 2.7l-0.3-0.3c0.7 0.1 1.3 0.5 1.7 0.9q0.6 0.8 0.8 1.7 0.2 0.8 0 1.7c-0.1 0.6-0.4 1.2-0.8 1.6z"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    id="&lt;Path&gt;"
                    class="s1"
                    d="m704.6 534.2c0 0-16.8-6.8-34.6-9-10.7 2.7-20 0.8-31.6 1.3-31.8 1.1-36 30-36 30l3.9 142.8 104.3-3.2z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s1"
                    d="m704.6 534.2c0 0-16.8-6.8-34.6-9-10.7 2.7-20 0.8-31.6 1.3-31.8 1.1-36 30-36 30l3.9 142.8 104.3-3.2z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s4"
                    d="m709.8 676.2l-5.1-140.6q-0.3-0.9-0.6-1.6c-2.2-0.9-12.5-4.8-24.9-7.3-2.3 5-7.3 10.3-17.9 10.3-15.6 0-24.5-4-29.6-9.8-25.7 4.6-29.3 29.3-29.3 29.3l3.4 125c18 2.5 73.7 8.4 104-5.3z"
                  />
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m710.2 690.1c0 0-6.5 0.3-16.2 0.7-9.7 0.4-22.6 1-35.6 1.5-12.9 0.4-25.9 0.9-35.6 1.2-9.7 0.2-16.2 0.4-16.2 0.4 0 0 6.5-0.3 16.2-0.7 9.7-0.4 22.6-0.9 35.6-1.5 13-0.4 25.9-0.8 35.6-1.2 9.7-0.2 16.2-0.4 16.2-0.4z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Clip Group&gt;" clip-path="url(#cp1)"></g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;"></g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m624 698.8c0 0-4 15.9-9.4 39.8-2.8 12-5.8 25.9-8.9 41-1.5 7.5-3.1 15.2-4.5 23.2-1.4 7.9-2.7 15.9-3.8 24-0.3 2.1-0.4 4.1-0.7 6.1-0.2 2.1-0.5 4.1-0.5 6.1-0.1 2-0.2 4.1-0.3 6.1q0 3 0 6c0 8.1 0 16 0 23.7 0 15.3 0 29.6 0 41.9 0 24.5 0 40.9 0 40.9 0 0-0.1-16.4-0.3-40.9 0-12.3 0-26.6-0.1-41.9 0-7.7 0-15.6-0.1-23.7q0-3 0-6c0-2 0.2-4.1 0.3-6.1 0-2.1 0.3-4.1 0.5-6.1 0.2-2.1 0.3-4.1 0.7-6.1 1-8.1 2.4-16.2 3.8-24.1 1.5-8 3-15.7 4.6-23.2 3.1-15 6.2-29 9-41 5.6-23.9 9.7-39.7 9.7-39.7z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m671.4 741.1c0 0 1.5 13.6 3.8 34 2.2 20.4 5.2 47.6 8.1 74.8 2.9 27.2 5.8 54.4 7.9 74.8 2.1 20.4 3.5 34 3.5 34 0 0-1.5-13.6-3.8-34-2.2-20.4-5.2-47.6-8.1-74.8-2.9-27.2-5.8-54.4-7.9-74.8-2.1-20.4-3.5-34-3.5-34z"
                      />
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <g id="&lt;Group&gt;">
                      <path
                        id="&lt;Path&gt;"
                        class="s5"
                        d="m674 525.8c0 0-2.3 1.1-5.8 2.4q-1.3 0.5-2.8 1c-1 0.3-2.1 0.6-3.3 0.9-2.2 0.5-4.7 1-7.2 1.2-1.2 0.1-2.5 0-3.7 0.1-1.2-0.1-2.5-0.2-3.6-0.3-1.2-0.2-2.3-0.4-3.3-0.7-1-0.4-2-0.7-2.9-1q-1.2-0.7-2.2-1.3c-0.7-0.3-1.2-0.8-1.6-1.1-0.9-0.7-1.3-1.1-1.3-1.1 0 0 0.5 0.4 1.4 1 0.4 0.3 0.9 0.7 1.6 1.1q1 0.5 2.2 1.1c0.9 0.3 1.9 0.6 2.9 0.9 1 0.3 2.1 0.4 3.2 0.6 1.2 0.1 2.4 0.2 3.6 0.3 1.2-0.1 2.4 0 3.7-0.2 2.4-0.2 4.9-0.6 7.1-1.1 2.3-0.5 4.4-1.1 6.1-1.7 3.6-1.1 5.9-2.1 5.9-2.1z"
                      />
                    </g>
                  </g>
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m629.2 476.9c0 1.8 0.5 3.2 1.2 3.2 0.6 0 1.1-1.4 1.1-3.2 0-1.8-0.5-3.2-1.1-3.2-0.7 0-1.2 1.4-1.2 3.2z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s7"
                    d="m625.6 477.8c0 0 0.3 7.3-1.5 9-1.8 1.8 1.1 5.4 3.6 3.6 2.6-1.8-2.1-12.6-2.1-12.6z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s14"
                    d="m628.2 498.9c2.4 3.4 3.3-2 3.3-2z"
                  />
                  <path
                    id="&lt;Path&gt;"
                    class="s9"
                    d="m637.4 490.4c0 1.4-1.8 2.5-3.9 2.5-2.2 0-3.9-1.1-3.9-2.5 0-1.4 1.7-2.6 3.9-2.6 2.1 0 3.9 1.2 3.9 2.6z"
                  />
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s7"
                      d="m563.7 711.8c0.1 5.9-1.8 14.2-3.6 14.3-1.7 0.1-2.8-2-2.8-2 0 0-0.8 4.3-3.2 4.5-2.4 0.2-3-4.4-3-4.4 0 0-0.5 4.9-2.8 4.4-2.3-0.5-2.6-5.6-2.6-5.6 0 0-1.1 8.7-2.9 8.4-1.8-0.3-3.2-10.5-3.6-22.3 0 0-0.5 8.9-3 9.1-2.4 0.2-8.5-18.9 6.9-32.5"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s7"
                      d="m562.2 695.9c0 0 4.8 23.4-2.6 25.8-7.3 2.4-23.6 3.2-20.4-24.9 3.3-28 23-0.9 23-0.9z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s1"
                      d="m587.7 597.4l5.2-30.3c2.7-15.5 12.5-30.5 27.4-36 3.1-1.1 28 15.4 10.8 70.1-13.9 4.7-29.7 3.9-43.4-3.8z"
                    />
                    <path
                      id="&lt;Path&gt;"
                      class="s7"
                      d="m541.9 687.5l42.5-71.4 3.3-18.7c13.7 7.7 29.5 8.5 43.4 3.8q0 0.1 0 0.2c-3.4 12-9.4 25.8-17.2 35.4l-59.8 72z"
                    />
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m545.8 716.6c0 0 0.1 0.6 0.2 1.5 0 0.9 0.1 2.1 0 3.3-0.1 1.2-0.2 2.4-0.4 3.2-0.2 0.9-0.4 1.5-0.4 1.5 0 0 0.1-0.6 0.1-1.5 0-0.9 0.1-2.1 0.2-3.3 0-1.2 0.1-2.4 0.2-3.2 0-0.9 0.1-1.5 0.1-1.5z"
                        />
                      </g>
                    </g>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m551.4 718.1c0 0 0.1 0.6 0.2 1.3 0.1 0.8 0.1 1.9 0 2.9-0.1 1.1-0.3 2.1-0.5 2.9-0.3 0.7-0.4 1.2-0.4 1.2 0 0 0-0.5 0-1.3 0.1-0.7 0.2-1.8 0.3-2.8 0.1-1 0.2-2.1 0.3-2.9 0.1-0.7 0.1-1.3 0.1-1.3z"
                        />
                      </g>
                    </g>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m557.4 719.7c0 0 0.1 0.5 0.1 1.1 0.1 0.6 0 1.5-0.1 2.4-0.1 0.8-0.2 1.6-0.5 2.2-0.2 0.7-0.4 1-0.4 1 0 0 0.2-1.6 0.4-3.3 0.1-0.8 0.2-1.7 0.3-2.3 0.1-0.6 0.2-1.1 0.2-1.1z"
                        />
                      </g>
                    </g>
                    <g id="&lt;Group&gt;">
                      <g id="&lt;Group&gt;">
                        <path
                          id="&lt;Path&gt;"
                          class="s5"
                          d="m589.6 590.4c0 0 2.4 1.5 6.5 3.1 2 0.7 4.4 1.5 7 2.1q2 0.4 4.1 0.7c1.4 0.3 2.8 0.4 4.3 0.5 1.4 0.1 2.8 0.1 4.2 0.2q2.2-0.1 4.2-0.2 2-0.2 3.9-0.4c1.2-0.1 2.3-0.4 3.4-0.5 1.1-0.2 2-0.4 2.9-0.6q1.3-0.3 2.2-0.6c1.2-0.3 1.9-0.5 1.9-0.5 0 0-0.7 0.3-1.9 0.6q-0.9 0.3-2.2 0.7c-0.8 0.3-1.8 0.4-2.8 0.7-1.1 0.2-2.2 0.5-3.5 0.6-1.2 0.2-2.5 0.4-3.9 0.5q-2 0.1-4.2 0.2c-1.4 0-2.8 0-4.3-0.1-1.4-0.2-2.9-0.3-4.3-0.5q-2.1-0.4-4.1-0.9c-1.3-0.2-2.6-0.7-3.8-1-1.1-0.4-2.2-0.8-3.2-1.2-4.1-1.7-6.4-3.4-6.4-3.4z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="&lt;Group&gt;">
                    <path
                      id="&lt;Path&gt;"
                      class="s5"
                      d="m729 602.6c0 0 0.2 1.4 0.6 3.7 0.3 2.2 0.7 5.2 1.1 8.1 0.3 3 0.7 6 0.9 8.2 0.2 2.3 0.3 3.8 0.3 3.8 0 0-0.3-1.5-0.6-3.7-0.4-2.3-0.8-5.2-1.2-8.2-0.3-3-0.6-6-0.8-8.2-0.2-2.3-0.3-3.7-0.3-3.7z"
                    />
                  </g>
                  <path
                    id="&lt;Path&gt;"
                    class="s5"
                    d="m613.8 451.1c-5.2-18 6.8-28.5 8.6-23.7 1.7 4.7 11.5-11.3 13.9-2.3 2.3 9 16.1-9.5 21.6 1 5.5 10.5 23.7-2 23.4 10.6-0.3 12.6 14.8 7.4 2.6 36.2-12.3 28.7-30.8-22.3-43-23-12.3-0.8-27.1 1.2-27.1 1.2z"
                  />
                </g>
              </g>
              <path
                id="&lt;Path&gt;"
                class="s6"
                d="m217.4 451.7c0 0-8.2 12-19.7 16-8.6 3-16.5 18.4-19.7 34.3-2.2 11.2-15.9 15.6-23.9 7.5q-0.3-0.3-0.6-0.6c-6.3-6.7-5.4-16.9 0.6-24 1.8-2.2 1.6-5.9-0.6-11.3-4-9.8 4.7-14.4 8-17.4 3.2-3 0.2-18.5 11.2-21.3 11-2.7 15.8-22 30.9-11 15.1 10.9 27.8-1.2 30.9 9.8 3.1 11 14 26.2-5.6 30.3-1.2-3.6-5.9-2.8-11.5-12.3z"
              />
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Path&gt;"
                  class="s5"
                  d="m189.4 474.5c0 0-0.5 1.1-1.6 2.9-1 1.9-2.4 4.6-4 7.8-0.8 1.6-1.6 3.4-2.3 5.3q-1.2 2.9-2 6.2c-0.6 2.2-1 4.5-1.7 6.8-0.7 2.3-2.1 4.5-4.1 6-2 1.5-4.5 2.2-6.9 2.4-2.3 0.3-4.7-0.1-6.9 0.4-2.2 0.4-4.2 1.4-5.6 2.9-1.5 1.4-2.4 3.2-2.9 4.9-0.4 1.7-0.7 3.4-0.6 4.8-0.1 1.5 0.1 2.8 0.2 3.8 0.3 2.1 0.5 3.3 0.5 3.3 0 0-0.2-1.2-0.6-3.3-0.1-1-0.4-2.3-0.3-3.8-0.1-1.4 0.1-3.1 0.5-4.9 0.5-1.7 1.4-3.6 2.9-5.1 1.5-1.5 3.6-2.6 5.8-3 2.3-0.6 4.7-0.2 7-0.5 2.3-0.3 4.7-0.9 6.6-2.3 1.9-1.4 3.2-3.5 3.9-5.7 0.8-2.3 1.2-4.6 1.8-6.8 0.6-2.2 1.2-4.3 2-6.2 0.8-1.9 1.6-3.7 2.5-5.3 1.6-3.3 3.1-5.9 4.2-7.7 1-1.9 1.6-2.9 1.6-2.9z"
                />
              </g>
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Path&gt;"
                  class="s5"
                  d="m215.5 438c0 0 0.9 1.3 2.3 3.2 1.3 1.9 3.2 4.3 5.2 6.6 1.1 1.2 2.1 2.3 3.2 3.3 1.1 0.9 2.1 1.8 3.1 2.4 0.4 0.4 0.9 0.6 1.3 0.9 0.4 0.2 0.8 0.4 1.1 0.5 0.6 0.3 1 0.4 1 0.4 0 0-0.4-0.1-1-0.3-0.4-0.1-0.8-0.2-1.2-0.5-0.4-0.2-0.9-0.4-1.4-0.7-1-0.6-2.1-1.4-3.2-2.4-1.1-0.9-2.2-2-3.3-3.2-2-2.3-3.9-4.9-5.1-6.8-1.3-2-2-3.4-2-3.4z"
                />
              </g>
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Path&gt;"
                  class="s5"
                  d="m206.6 436c0 0-0.1 1.1-0.3 2.9-0.3 1.8-0.7 4.4-1.8 7.3-0.5 1.5-1.2 3-2.1 4.6-0.9 1.6-2 3.1-3.4 4.5-0.7 0.8-1.5 1.4-2.2 2-0.9 0.6-1.7 1.2-2.6 1.7-1.8 1-3.7 2-5.5 3-1.8 1-3.7 2-5.4 3q-2.6 1.6-4.9 3.4c-1.5 1.3-2.9 2.5-4.1 3.9q-0.9 1-1.6 2-0.8 1-1.4 2c-0.9 1.3-1.5 2.6-2.1 3.7-0.5 1.1-1 2.1-1.3 3-0.6 1.7-0.9 2.7-0.9 2.7 0 0 0.3-1 0.8-2.8 0.3-0.8 0.8-1.8 1.2-3 0.6-1.2 1.2-2.5 2-3.8q0.6-1 1.4-2 0.7-1.1 1.7-2.1c1.1-1.3 2.5-2.6 4-3.9q2.3-1.9 4.9-3.5c1.8-1.1 3.6-2.1 5.4-3.1 1.9-1 3.7-1.9 5.5-2.9 0.9-0.5 1.7-1.1 2.6-1.6 0.8-0.7 1.5-1.3 2.2-2 1.4-1.3 2.5-2.8 3.4-4.4 0.9-1.5 1.6-3 2.1-4.5q0.9-2.2 1.3-4c0.3-1.3 0.5-2.4 0.7-3.3 0.3-1.7 0.4-2.8 0.4-2.8z"
                />
              </g>
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m161.4 566.7l1.6 6.3 5.3-4.7z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m179.8 587.6l-0.6 6.4 6.6-2.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m160.2 612.9l-1.7 6.3 7-1.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m162 552.9l-6.4 1.4 4.5 5.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m171.3 553.1l-0.6 6.5 6.6-2.7z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m222.1 555.9l-5.7 3.1 5.8 4.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m225.5 547.5l-6.5-0.4 2.9 6.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m246.4 572.6l6.1 2.3-0.8-7.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m255.4 574l-4.5 4.7 6.8 2z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m236.2 566.3l1 6.4 5.7-4.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m175.6 546.6l4.3 4.8 2.6-6.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m164.4 542.8l3.7 5.3 3.4-6.3z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m178.8 532.9l-6.3 1.5 4.6 5.4z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m180 563.7l-6.3 1.8 4.9 5.2z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m160.3 605.3l4.2 4.9 2.8-6.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m236.3 552.4l-5.8 3 5.8 4.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m240.6 562.6l6.2 2.1-1-7.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m193.8 575.1l1 6.4 5.8-4.2z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m167.9 577.6l1.6 6.3 5.3-4.8z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m178 605.6l-6.4 1.3 4.5 5.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m161.1 592.5l-6.4 1.3 4.5 5.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m188.2 557.6l-5.1 4 6.4 3z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m196 562.1l5.6 3.3 0.4-7.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m224.6 578l3.8 5.3 3.3-6.3z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m224.9 590.2l3.2 5.7 3.9-5.9z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m183.1 572.3l0.1 6.5 6.3-3.3z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m156.9 574.4l1.5 6.4 5.4-4.7z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m210.9 547.6l-1.9 6.3 7-1.3z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m218.2 538.8l-3.4 5.5 7.1 0.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m198.6 550.2l-6.5-0.4 2.9 6.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m247.3 580l-6.2-2.2 1 7.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m239.5 588.8l-0.2 6.5 6.4-3.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m218.2 568.2l-0.1 6.5 6.4-3.2z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m168.2 591.8l5.7 3 0.1-7.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m190.3 626.9l3.6 5.4 3.4-6.3z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m209.4 647l4 5.1 2.9-6.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m165.8 637.8l1.3 6.4 5.5-4.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m166.4 663.9l1.6 6.3 5.4-4.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m159.3 672.2l-1.2 6.4 6.9-2z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m173.9 621.9l-5.5 3.5 6.1 3.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m185.2 643.5l-5.6 3.3 6.1 3.8z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m147.3 673.6l1.4 6.3 5.5-4.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m154.9 650.9l6 2.4-0.7-7.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m178.5 633l3.6 5.3 3.4-6.2z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m152.8 661.1l3.1 5.7 4-5.9z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m166.1 685.1l-6.3-1.8 1.4 7z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m171 650l-1 6.4 6.8-2.3z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m167.8 680.1l5.6 3.3 0.4-7.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m181.8 624.7l6-2.3-5.3-4.8z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m195.8 637.6l-0.4 6.5 6.5-2.9z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m189.2 658.1l-6.5 0.7 4 5.9z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m149.8 680.9l-2.9 5.9 7.1-0.1z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m177.6 672.9l5.1 4 1.3-7z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m195.1 652.5l2.6 6 4.5-5.5z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m161.7 626.3l-2.3 6.1 7.1-0.7z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s13"
                  d="m206.9 566.7l-3.8 5.2 7 1.2z"
                />
              </g>
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Compound Path&gt;"
                  class="s8"
                  d="m505.2 269.5c5.4 2.6 13.5 5.2 21.7 5.2 8.7 0 13-3.6 12.4-9.1-0.5-5.3-4.8-8.3-15.4-11.9-14.5-4.9-24.4-12.7-25.7-24.9-1.5-14.4 9.4-25.5 29.3-25.5 9.5 0 16.7 2 22 4.3l-2.7 15.4c-3.5-1.6-9.8-4-18.1-4-8.2 0-11.9 3.7-11.4 8.1 0.6 5.4 5.6 7.8 16.9 11.9 15.5 5.5 23.3 13.3 24.5 25.2 1.5 14.1-8.2 26.3-31.3 26.3-9.7 0-19.5-2.6-24.5-5.3z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s8"
                  d="m531.2 301.8h-5.5v-109.7h5.5z"
                />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="s17" d="m528.5 160.3v18" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="s17" d="m498.8 165.5l6.2 17" />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s17"
                    d="m472.8 180.5l11.6 13.9"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="s17" d="m453.4 203.6l15.7 9" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="s17" d="m587.8 212.6l15.7-9" />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s17"
                    d="m572.5 194.4l11.6-13.9"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="s17" d="m551.9 182.5l6.2-17" />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Compound Path&gt;"
                  class="s3"
                  d="m447.2 297.1c2.7 1.3 6.8 2.7 10.9 2.7 4.4 0 6.6-1.9 6.3-4.6-0.3-2.7-2.5-4.2-7.8-6-7.3-2.5-12.3-6.4-12.9-12.5-0.7-7.3 4.7-12.8 14.7-12.8 4.8 0 8.4 1 11.1 2.1l-1.4 7.8c-1.8-0.8-4.9-2-9.1-2-4.1 0-5.9 1.9-5.7 4.1 0.3 2.7 2.8 3.9 8.5 5.9 7.8 2.8 11.7 6.7 12.3 12.7 0.7 7.1-4.1 13.2-15.7 13.2-4.9 0-9.8-1.3-12.3-2.6z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s3"
                  d="m460.3 313.4h-2.8v-55.2h2.8z"
                />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="s18" d="m458.9 242.3v9" />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="s18" d="m444 244.9l3.1 8.5" />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m430.9 252.5l5.9 6.9"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="s18" d="m421.2 264l7.9 4.6" />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m488.7 268.6l7.9-4.6"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m481.1 259.4l5.8-6.9"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m470.7 253.4l3.1-8.5"
                  />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Compound Path&gt;"
                  class="s3"
                  d="m588.7 270.3c2 0.9 5.1 1.9 8.1 1.9 3.3 0 4.9-1.3 4.7-3.4-0.3-1.9-1.9-3.1-5.8-4.4-5.4-1.8-9.1-4.7-9.6-9.3-0.5-5.4 3.5-9.5 10.9-9.5 3.6 0 6.3 0.8 8.2 1.6l-1 5.7c-1.3-0.6-3.6-1.5-6.7-1.5-3.1 0-4.4 1.4-4.3 3.1 0.2 2 2.1 2.9 6.3 4.4 5.8 2.1 8.7 5 9.2 9.4 0.5 5.3-3.1 9.8-11.7 9.8-3.6 0-7.3-1-9.1-2z"
                />
                <path
                  id="&lt;Path&gt;"
                  class="s3"
                  d="m598.4 282.3h-2v-40.9h2z"
                />
                <g id="&lt;Group&gt;">
                  <path id="&lt;Path&gt;" class="s18" d="m597.4 229.6v6.7" />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m586.3 231.5l2.4 6.3"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m576.6 237.1l4.4 5.2"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m569.4 245.7l5.9 3.4"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m619.5 249.1l5.9-3.4"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m613.8 242.3l4.4-5.2"
                  />
                </g>
                <g id="&lt;Group&gt;">
                  <path
                    id="&lt;Path&gt;"
                    class="s18"
                    d="m606.1 237.8l2.3-6.3"
                  />
                </g>
              </g>
              <g id="&lt;Group&gt;">
                <path
                  id="&lt;Path&gt;"
                  class="s5"
                  d="m611.9 431c-0.9 2.3-1.3 4.8-1.3 7.3 0 2.4 0.3 4.9 1 7.2 1.3 4.8 4.3 9 8.5 11.8-2.2-1.2-4.2-2.9-5.7-4.9-1.5-2-2.6-4.3-3.3-6.7-0.7-2.4-1.1-4.9-1-7.5 0.1-2.5 0.7-5 1.8-7.2z"
                />
              </g>
              <g id="&lt;Group&gt;"></g>
            </g>
          </g>
          <g id="DESIGNED BY FREEPIK">
            <g id="&lt;Group&gt;">
              <g id="&lt;Group&gt;">
                <g id="&lt;Group&gt;"></g>
              </g>
              <g id="&lt;Group&gt;"></g>
            </g>
          </g>
        </svg>
      </div>
  )
}

export default Tarjeta